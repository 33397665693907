.c-layout {/**/}
.c-layout__main {/**/}

.c-layout__header {
    padding: $spacing-medium;
}

.c-layout__footer {
    padding: 0 $spacing-medium;
}

.c-layout__container {
    width: 100%;
    margin: 0 auto;
    max-width: $site-width;
}

// Modifiers
.c-layout--slim {
    .c-layout__container {
        max-width: $site-width-narrow;
    }
}

.c-layout__container--slim {
    max-width: $site-width-narrow;
}
