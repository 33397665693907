//
// THIS FILE IS FOR REMAPPING OLD MARKUP ONLY
//
// - Do not add new styles.
// - 1:1 selectors extending (multiple modifiers and helpers are okay).
//

@import '../legacy/partials/sprites';

// Highlight all elements that uses new markup
// [class^="h-"], [class^="c-"] { outline: 4px solid #6ACA00; }

// Highlight all elements that doesn't use new markup (pretty buggy, but gives an idea)
// [class]:not([class*="h-"]):not([class*="c-"]) { outline: 1px dashed rgba(red, .2); }

html {
    background: $color-background;
}


// Site Main
.main {
    @extend .c-siteMain;
}


// Line / Grid
.line {
    @extend .h-clearfix;
}

.unit-right {
    @extend .h-float-right;
}


// Iframe promo
.iframe-parent {
    @extend .c-merchantBox;
}

.iframe-parent .inner {
    @extend .c-merchantBox__localContent;
}

.iframe-parent .r-shaded-bg {
    @extend .c-merchantBox__localContentInner;
}

.iframe-parent .last-unit {
    @extend .c-merchantBox__merchantContent;
}

.iframe-parent iframe {
    @extend .c-merchantBox__merchantIframe;
}


// SVGs
.svg-fallback {
    @extend .h-dn;
}


// Buttons
.btn {
    @extend .c-button;
    @extend .c-button--fullwidth;
}

.btn-img {
    @extend .c-button__image;
}

.btn-primary {
    @extend .c-button--primary;
}

.btn-slim {
    @extend .c-button--slim;
}

button.link {
    @extend .c-button;
    @extend .c-button--link;
}


// Media
.media {
    @extend .c-media;
}

.media > .img {
    @extend .c-media__image;
}

.media > .img-ext {
    @extend .c-media__imageExt;
}

.media > .bd {
    @extend .c-media__body;
}


// Payment method
.payment-method {
    @extend .c-paymentMethod;
}
.payment-method > * {
    @extend .h-db;
}

// Table
table {
    @extend .c-table;
}


// Form Element
.form-element {
    @extend .c-formElement;
}

.form-label {
    @extend .c-formElement__label;
}

input[type=radio], input[type=checkbox] {
    ~ .form-label {
        @extend .c-formElement__label--inline;
    }
}

.reveal-password-container {
    @extend .c-formElement__group;
}

.form-element .errors {
    @extend .c-formElement__errors;
}

.reveal-password {
    @extend .c-formElement__groupAddon;
}

.input {
    @extend .c-input;
}

.input.invalid,
.c-input.invalid {
    @extend .c-input--invalid;
}

// Notification
.alert, .info {
    @extend .c-alert;
}

// Agreement form
#agreement-form {
  .logo, .spid-logo { height: 20px; }
}

// Loader
.loader { @extend .c-loader }

// Helpers
.ib { @extend .h-dib; }
.hidden { @extend .h-dn; }
.show-lt-pad { @extend .h-dn; }

@media screen and (max-width: $mq-small) {
    .hide-lt-pad { @extend .h-dn\@sm; }
    .show-lt-pad { @extend .h-db\@sm; }
}

@media screen and (max-width: $mq-medium) {
    .hide-lt-grid { @extend .h-dn\@md; }
}

.right { @extend .h-float-right; }
.left { @extend .h-float-left; }

.center-block { display: block; margin-left: auto; margin-right: auto; }
.alcenter { @extend .h-text-center; }
.alright { @extend .h-text-right; }
.alleft { @extend .h-text-left; }
.nowrap { @extend .h-text-nowrap; }
.link { @extend .h-text-link; }
.justify { @extend .h-text-justify; }

.subtle, .faded { @extend .h-color-subtle; }

.normal { @extend .h-text-normal; }
.small-font { @extend .h-text-dn2; }
.subtle { @extend .h-text-dn2; }

.mod:not(:first-child):not(:last-child) { @extend .h-mvm; }
.hd, .bd, .ft { @extend .h-clearfix; }

.s1of1 { @extend .h-size1of1; }
.s1of2 { @extend .h-size1of2; }
.s1of3 { @extend .h-size1of3; }
.s1of4 { @extend .h-size1of4; }
.s1of5 { @extend .h-size1of5; }

.f1of2 { @extend .h-size1of2; }
.f1of3 { @extend .h-size1of3; }
.f2of3 { @extend .h-size2of3; }
.f1of4 { @extend .h-size1of4; }
.f3of4 { @extend .h-size3of4; }

@media screen and (max-width: $mq-medium) {
    .r1of1 { @extend .h-size1of1\@md; }
    .r1of2 { @extend .h-size1of2\@md; }
    .r1of3 { @extend .h-size1of3\@md; }
    .r1of4 { @extend .h-size1of4\@md; }
    .r1of5 { @extend .h-size1of5\@md; }
}

.man { @extend .h-man; }
.mtn { @extend .h-mtn; }
.mrn { @extend .h-mrn; }
.mln { @extend .h-mln; }
.mvn { @extend .h-mvn; }
.mhn { @extend .h-mhn; }

.mas { @extend .h-mas; }
.mts { @extend .h-mts; }
.mrs { @extend .h-mrs; }
.mls { @extend .h-mls; }
.mvs { @extend .h-mvs; }
.mhs { @extend .h-mhs; }

.mam { @extend .h-mam; }
.mtm { @extend .h-mtm; }
.mrm { @extend .h-mrm; }
.mlm { @extend .h-mlm; }
.mvm { @extend .h-mvm; }
.mhm { @extend .h-mhm; }

.mal { @extend .h-mal; }
.mtl { @extend .h-mtl; }
.mrl { @extend .h-mrl; }
.mll { @extend .h-mll; }
.mvl { @extend .h-mvl; }
.mhl { @extend .h-mhl; }

.pan { @extend .h-pan; }
.ptn { @extend .h-ptn; }
.prn { @extend .h-prn; }
.pln { @extend .h-pln; }
.pvn { @extend .h-pvn; }
.phn { @extend .h-phn; }

.pas { @extend .h-pas; }
.pts { @extend .h-pts; }
.prs { @extend .h-prs; }
.pls { @extend .h-pls; }
.pvs { @extend .h-pvs; }
.phs { @extend .h-phs; }

.pam { @extend .h-pam; }
.ptm { @extend .h-ptm; }
.prm { @extend .h-prm; }
.plm { @extend .h-plm; }
.pvm { @extend .h-pvm; }
.phm { @extend .h-phm; }

.pal { @extend .h-pal; }
.ptl { @extend .h-ptl; }
.prl { @extend .h-prl; }
.pll { @extend .h-pll; }
.pvl { @extend .h-pvl; }
.phl { @extend .h-phl; }
