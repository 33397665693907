.c-userThumbnail {
    background: $color-border;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 7em;
    height: 7em;
}

.c-userThumbnail__letter {
    color: $color-subtle;
    font-size: 3em;
    text-transform: uppercase;
}
