@mixin povered {
    width: 65px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center bottom;
}

.c-poweredBy {
    font-size: .6rem;
    text-align: center;

    &__logo {
        display: inline-block;
        margin-left: .5em;
        width: 3.5em;
        height: 1.5em;
        vertical-align: middle;
    }
    &__pci {
        @include povered;
        background-image: url(../../images/pci.png);
    }

    &__visa {
        @include povered;
        background-image: url(../../images/visa.png);
    }

    &__mc {
        @include povered;
        background-image: url(../../images/mc.png);
    }
}
