.c-collapser {
    background: none;
    border: none;
    cursor: pointer;
    font: inherit;
    font-size: .9rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;

    &::before {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMjAgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgIDxnIGZpbGw9ImN1cnJlbnRDb2xvciI+ICAgICAgICA8cGF0aCBkPSJNOS45OTgzNjkxOSw3LjYwMjE2NDggTDkuOTk4MzY5MTksNy42MDIxNjQ4IEw5Ljk5ODM2OTE5LDcuNjAyMTY0OCBMMTcuNzc1MTU0OSwwLjEzNzg3OTEzIEMxNy45NjcxMTkyLC0wLjA0OTYyMDg3IDE4LjI4NDA4MzUsLTAuMDQ1MTU2NTggMTguNDgwNTEyLDAuMTQ2ODA3NyBMMTkuODQ2NTgzNSwxLjQ4MTYyOTEzIEMyMC4wNDMwMTIsMS42NzM1OTM0MiAyMC4wNDc0NzYzLDEuOTg2MDkzNDIgMTkuODU1NTEyLDIuMTczNTkzNDIgTDEwLjM1OTk3NjMsMTEuMjg5NjY0OCBDMTAuMjYxNzYyLDExLjM4Nzg3OTEgMTAuMTI3ODMzNSwxMS40MzI1MjIgOS45OTgzNjkxOSwxMS40MjM1OTM0IEM5Ljg2NDQ0MDYxLDExLjQyODA1NzcgOS43MzQ5NzYzMywxMS4zODM0MTQ4IDkuNjM2NzYyMDQsMTEuMjg5NjY0OCBMMC4xNDEyMjYzMjksMi4xNzM1OTM0MiBDLTAuMDUwNzM3OTU3LDEuOTg2MDkzNDIgLTAuMDQ2MjczNjcxMywxLjY3MzU5MzQyIDAuMTUwMTU0OSwxLjQ4MTYyOTEzIEwxLjUxNjIyNjMzLDAuMTQ2ODA3NyBDMS43MTI2NTQ5LC0wLjA0NTE1NjU4IDIuMDI5NjE5MTksLTAuMDQ5NjIwODcgMi4yMjE1ODM0NywwLjEzNzg3OTEzIEw5Ljk5ODM2OTE5LDcuNjAyMTY0OCBMOS45OTgzNjkxOSw3LjYwMjE2NDggTDkuOTk4MzY5MTksNy42MDIxNjQ4IFoiPjwvcGF0aD4gICAgPC9nPjwvc3ZnPg==);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        margin: 0 .5em;
        vertical-align: middle;
        transition: transform .2s;
    }

    &.is-open {
        &::before {
            transform: rotate(-180deg);
        }
    }
}
