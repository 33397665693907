.c-formElement {
    margin: $spacing-medium 0;
}

.c-formElement__label {
    display: block;
    font-weight: 400;
    margin-bottom: $spacing-small;

    &--inline {
        display: inline;
    }
}

.c-formElement__group {
    display: flex;
    width: 100%;
}

.c-formElement__groupAddon {
    align-self: center;
    font-size: .8em;
    font-weight: 400;
    padding: 0 $spacing-medium;
}

.c-formElement__errors {
    color: $color-danger;
    display: block;
    font-size: .8em;
    margin: $spacing-small 0;
}

.birthday {
    overflow: auto;
    .form-element {
        display: inline-block;
        margin: 0 1em 0 0;
        &:last-of-type {
            margin-right: 0;
        }
        input {
            border: 1px solid #999;
        }
        .birthdayerror {
            border: 1px solid #c00;
        }
    }
}

.form-element::after {
    content: "";
    display: block;
    clear: both;
}
