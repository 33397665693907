@mixin createSizeGroups (
    $groups: 5,
    $suffix: ''
) {
    @for $group from 1 through $groups {
        @for $size from 1 through $group {
            #{'.h-size' + $size + 'of' + $group + $suffix} {
                width: percentage($size / $group) !important;
            }
        }
    }
}

@include createSizeGroups();

@media screen and (max-width: $mq-small) {
    @include createSizeGroups($suffix: \@sm);
}

@media screen and (max-width: $mq-medium) {
    @include createSizeGroups($suffix: \@md);
}
