html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    margin: 0;
}

a img {
  border: none;
}

hr {
    border: 1px solid $color-border;
    border-bottom: none;
    margin: $spacing-medium 0;
}

img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}
