$spacing-small: .5rem !default;
$spacing-medium: 1rem !default;
$spacing-large: 2rem !default;

// Margins

.h-mtn { margin-top: 0 !important; }
.h-mrn { margin-right: 0 !important; }
.h-mbn { margin-bottom: 0 !important; }
.h-mln { margin-left: 0 !important; }
.h-mvn { @extend .h-mtn, .h-mbn; }
.h-mhn { @extend .h-mrn, .h-mln; }
.h-man { @extend .h-mvn, .h-mhn; }

.h-mts { margin-top: $spacing-small !important; }
.h-mrs { margin-right: $spacing-small !important; }
.h-mbs { margin-bottom: $spacing-small !important; }
.h-mls { margin-left: $spacing-small !important; }
.h-mvs { @extend .h-mts, .h-mbs; }
.h-mhs { @extend .h-mrs, .h-mls; }
.h-mas { @extend .h-mvs, .h-mhs; }

.h-mtm { margin-top: $spacing-medium !important; }
.h-mrm { margin-right: $spacing-medium !important; }
.h-mbm { margin-bottom: $spacing-medium !important; }
.h-mlm { margin-left: $spacing-medium !important; }
.h-mvm { @extend .h-mtm, .h-mbm; }
.h-mhm { @extend .h-mrm, .h-mlm; }
.h-mam { @extend .h-mvm, .h-mhm; }

.h-mtl { margin-top: $spacing-large !important; }
.h-mrl { margin-right: $spacing-large !important; }
.h-mbl { margin-bottom: $spacing-large !important; }
.h-mll { margin-left: $spacing-large !important; }
.h-mvl { @extend .h-mtl, .h-mbl; }
.h-mhl { @extend .h-mrl, .h-mll; }
.h-mal { @extend .h-mvl, .h-mhl; }


// Paddings

.h-ptn { padding-top: 0 !important; }
.h-prn { padding-right: 0 !important; }
.h-pbn { padding-bottom: 0 !important; }
.h-pln { padding-left: 0 !important; }
.h-pvn { @extend .h-ptn, .h-pbn; }
.h-phn { @extend .h-prn, .h-pln; }
.h-pan { @extend .h-pvn, .h-phn; }

.h-pts { padding-top: $spacing-small !important; }
.h-prs { padding-right: $spacing-small !important; }
.h-pbs { padding-bottom: $spacing-small !important; }
.h-pls { padding-left: $spacing-small !important; }
.h-pvs { @extend .h-pts, .h-pbs; }
.h-phs { @extend .h-prs, .h-pls; }
.h-pas { @extend .h-pvs, .h-phs; }

.h-ptm { padding-top: $spacing-medium !important; }
.h-prm { padding-right: $spacing-medium !important; }
.h-pbm { padding-bottom: $spacing-medium !important; }
.h-plm { padding-left: $spacing-medium !important; }
.h-pvm { @extend .h-ptm, .h-pbm; }
.h-phm { @extend .h-prm, .h-plm; }
.h-pam { @extend .h-pvm, .h-phm; }

.h-ptl { padding-top: $spacing-large !important; }
.h-prl { padding-right: $spacing-large !important; }
.h-pbl { padding-bottom: $spacing-large !important; }
.h-pll { padding-left: $spacing-large !important; }
.h-pvl { @extend .h-ptl, .h-pbl; }
.h-phl { @extend .h-prl, .h-pll; }
.h-pal { @extend .h-pvl, .h-phl; }
