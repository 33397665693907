.c-lineItem {
  margin: $spacing-medium 0;

  &__header {
    overflow: hidden;
    margin-bottom: $spacing-small;
  }

  &__title {
    float: left;
    font-size: 1.1em;
    margin: 0;
  }

  &__meta {
    float: right;
    text-align: right;
    margin: 0;
  }

  &__description {
    font-size: .9em;
    color: $color-subtle;
    clear: both;
  }
}
