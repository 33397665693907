.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(#fff, .85);
    z-index: 98;
    display: none;
    top: 0;
    left: 0;
    &__show {
        display: block;
    }
}
