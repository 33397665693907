.c-toggleIcon {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: middle;

  &__pressed,
  &__unpressed  {
     width: 1.5em;
     height: 1.5em;
     vertical-align: middle;
  }
}

// States
.c-toggleIcon {
  .c-toggleIcon__pressed { display: none; }
  .c-toggleIcon__unpressed { display: inherit; }
}

.c-toggleIcon.is-pressed {
   .c-toggleIcon__pressed { display: inherit; }
   .c-toggleIcon__unpressed { display: none; }
}
