.c-inputPhone {
    position: relative;
    width: 100%;

    &__prefix {
        padding: .5em .8em;
        position: absolute;
        top: 1px;
        left: 1px;
        pointer-events: none;
    }

    &__input {
        padding-left: 3em;
    }
}
.iti {
    width: 100%;
}
