.c-moneyFormat {

  &__amount {
    font-weight: 400;
  }

  &__currency {
    font-size: .8em;
  }
}
