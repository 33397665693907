.h-dib { display: inline-block !important; }
.h-db { display: block !important; }
.h-di { display: inline !important; }

.h-dn { display: none !important; }

@media screen and (max-width: $mq-small) {
  .h-db\@sm { display: block !important; }
  .h-dn\@sm { display: none !important; }
}

@media screen and (max-width: $mq-medium) {
  .h-db\@md { display: block !important; }
  .h-dn\@md { display: none !important; }
}

@media screen and (max-width: $mq-large) {
  .h-db\@lg { display: block !important; }
  .h-dn\@lg { display: none !important; }
}
