.c-providerLogo {
    display: block;
    width: 3.4em;
    height: 1.85em;
    text-align: center;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}
