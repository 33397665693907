.c-media {
    display: block;
    overflow: hidden;
}

.c-media__image {
    display: block;
    float: left;
    margin-right: $spacing-medium;
}

.c-media__imageExt {
    display: block;
    float: right;
    margin-left: $spacing-medium;
}

.c-media__body {
    display: block;
    overflow: hidden;
}
