.c-input {
    border: 1px solid $color-border-dk;
    font: inherit;
    padding: .5em .8em;
    width: 100%;

    &--invalid {
        border-color: $color-danger;
    }

    &--shortNumber {
        width: 5em;
    }
}
