$providers: (
    mc visadankort visa amex vias diners maestrouk solo laser discover jcb bcmc bijcard dankort hipercard maestro elo uatp cup cartebancaire visaalphabankbonus mcalphabankbonus karenmillen oasis warehouse
);

.c-input-cc {
    background-repeat: no-repeat;
    background-image: url(../../images/flow/credit-card.png);
    background-position: 16px 10px;
    text-indent: 42px;

    @each $provider in $providers {
        &__#{$provider} {
            background-position: 8px 8px;
            background-image: url(../../images/ccLogos/#{$provider}.png);
            background-size: 34px !important;
        }
    }
}
