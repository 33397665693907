html {
    font: 300 16px 'Open Sans', 'Helvetica', 'Arial', sans-serif;
}

body {
    font-size: 1rem;
    line-height: $line-height;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}

a, %tag-a {
    color: $color-link;
    cursor: pointer;
    text-decoration: underline;
}

strong {
    font-weight: 400;
}
