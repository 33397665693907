.c-checkSwitch {
    $size: 1.5em;

    appearance: none;
    background-color: $color-content;
    border: 1px solid $color-border;
    border-radius: $size;
    box-sizing: content-box;
    cursor: pointer;
    display: inline-block;
    font-size: inherit;
    overflow: hidden;
    margin: 0;
    padding: 2px;
    position: relative;
    width: $size * 2;
    height: $size;
    vertical-align: middle;

    &__input {
        position: absolute;
        left: 0;
        opacity: 0;
    }

    &__switch {
        background: $color-danger;
        border-radius: 100%;
        display: block;
        position: absolute;
            top: 2px;
            left: 2px;
        transition: transform 200ms ease, background 200ms;
        width: $size;
        height: $size;
    }

    &.is-checked > &__switch,
    &__input:checked + &__switch {
        background: $palette-teal-50;
        transform: translate(100%, 0);
    }
}
