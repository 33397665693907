@import '../base/variables';
@import '../utils/color-map';

@mixin color-helpers($map) {
  @each $name, $color in $map {
    .h-bg-#{$name} {
      background-color: $color !important;
    }

    .h-color-#{$name} {
      color: $color !important;
    }

    .h-border-#{$name} {
      border-color: $color !important;
    }
  }
}

@include color-helpers($color-map);
