.c-siteHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__brand {
        flex-shrink: 0;
        max-width: 60%;

        @media (max-width: $mq-small) {
            max-width: 50%;
        }
    }

    &__brandImg {
        max-width: 100%;
        max-height: 60px;
        vertical-align: middle;
    }

    &__SchibstedImg {
        max-width: 100%;
        max-height: 16px;
        vertical-align: middle;
        margin-top: auto;
    }

    &__menu {
        display: flex;
        font-size: .8em;
        justify-content: flex-end;
        overflow: hidden;
        margin-left: $spacing-small;

        > * {
            margin-left: $spacing-small;
            white-space: nowrap;
        }
    }

    &__userName {
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: $mq-small) {
            display: none;
        }
    }
}


// Make up for lack of flex in IE9
.c-siteHeader {
    overflow: hidden;
    &__brand { float: left; }
    &__menu { float: right; }
}
