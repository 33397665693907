//
// Sprites are currently manually updated
// and is no longer generated by Compass.
//
// TODO Automate this through Gulp
//
.payment-klarna, .payment-mastercard, .payment-mastercard-securecode, .payment-payex, .payment-sprite, .payment-strex, .payment-verified-by-visa, .payment-visa, .payment-wywallet {
	background-image: url(../../images/payment/sprite.png);
	background-repeat: no-repeat
}
.payment-klarna {
	background-position: 0 0;
	height: 25px;
	width: 66px
}
.payment-mastercard-securecode {
	background-position: 0 -25px;
	height: 25px;
	width: 55px
}
.payment-mastercard {
	background-position: 0 -50px;
	height: 25px;
	width: 42px
}
.payment-payex {
	background-position: 0 -75px;
	height: 25px;
	width: 71px
}
.payment-strex {
	background-position: 0 -100px;
	height: 25px;
	width: 58px
}
.payment-verified-by-visa {
	background-position: 0 -125px;
	height: 25px;
	width: 43px
}
.payment-visa {
	background-position: 0 -150px;
	height: 25px;
	width: 39px
}
.payment-wywallet {
	background-position: 0 -175px;
	height: 25px;
	width: 52px
}

@mixin bg() {
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
}
.fortumo-logos {
    display: flex;
    justify-content: space-between;
    & .--tele2 {
        @include bg();
        background-image: url('../../images/payment/tele2.png');
        width: 60px;
    }
    .--tree-mobile {
        @include bg();
        background-image: url('../../images/payment/3-mobile-vector-logo.png');
        width: 40px;
    }
    .--telia {
        @include bg();
        background-image: url('../../images/payment/telia_p_rgb.png');
        width: 65px;
    }
    .--telenor {
        @include bg();
        background-image: url('../../images/payment/telenor_logo.png');
        width: 54px;
    }
    .--hallon {
        @include bg();
        background-image: url('../../images/payment/hallon.png');
        width: 54px;
    }
    .--comviq {
        @include bg();
        background-image: url('../../images/payment/comviq.png');
        width: 54px;
    }
}
