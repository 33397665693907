.c-merchantBox {
    display: flex;
    height: 100%;
    position: relative;
}

.c-merchantBox__merchantContent {
    order: 1;
    flex: 1;
    margin-right: $spacing-large;
}

.c-merchantBox__merchantIframe {
    width: 100%;
}

.c-merchantBox__localContent {
    order: 2;
    width: 350px;

    @media screen and (max-width: $mq-medium) {
        width: 100%;
    }
}

.c-merchantBox__localContentInner {
    background: $color-background;
    padding: $spacing-small $spacing-large;

    @media screen and (max-width: $mq-medium) {
        background: transparent;
        padding: 0;
    }
}
