$loader-ball-size: 30px;
$loader-ball-spacing: 10px;
$loader-ball-color-start: #fff;
$loader-ball-color-end: #333;

.c-loader {
  height: $loader-ball-size;
  width: $loader-ball-size;
  border-radius: 50%;
  background-color: $loader-ball-color-start;
  position: relative;
  margin: 20px auto;
  animation: loader-balls 2s infinite 0.6s linear;
}

.c-loader:before {
  content: ' ';
  height: $loader-ball-size;
  width: $loader-ball-size;
  border-radius: 50%;
  background-color: $loader-ball-color-start;
  position: absolute;
  right: $loader-ball-size + $loader-ball-spacing;
  animation: loader-balls 2s infinite 0.1s linear;
}

.c-loader:after {
  content: ' ';
  height: $loader-ball-size;
  width: $loader-ball-size;
  border-radius: 50%;
  background-color: $loader-ball-color-start;
  position: absolute;
  left: $loader-ball-size + $loader-ball-spacing;
  animation: loader-balls 2s infinite 1.1s linear;
}

@keyframes loader-balls {
  0% {}
  40% { background-color:$loader-ball-color-end; }
  80% { background-color:$loader-ball-color-start; }
  100% {}
}
