.h-text-center { text-align: center; }
.h-text-right { text-align: right; }
.h-text-left { text-align: left; }
.h-text-justify { text-align: justify; }

.h-text-nowrap { white-space: nowrap; }

.h-text-truncate {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.h-text-link { @extend %tag-a; }

.h-text-normal { font-size: 1rem; }

.h-text-up1 { font-size: 1.2rem; }

.h-text-dn1 { font-size: .9rem; }
.h-text-dn2 { font-size: .8rem; }
.h-text-dn3 { font-size: .7rem; }
