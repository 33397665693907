.c-paymentMethod {
    border: 1px solid $color-border;
    border-radius: $radius1;
    cursor: pointer;
    display: block;
    margin: $spacing-small 0;
    padding: $spacing-medium;

    &.is-active {
        background: #f8f9f9;
    }
}

.c-paymentMethod__providerImage {
    vertical-align: middle;
    margin: 0 $spacing-small;
}
