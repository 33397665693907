@import '../utils/color-map';

$mq-small:   480px;
$mq-medium:  700px;
$mq-large:   1000px;
$mq-xlarge:  1300px;

$palette-red: #cc0000;
$palette-yellow-90: #FCF8E3;
$palette-blue-50: #3366cc;
$palette-blue-30: #004f91;
$palette-teal-50: #30A0C0;
$palette-gray-95: #f3f3f3;
$palette-gray-60: #999999;
$palette-gray-90: #e6e6e6;
$palette-gray-50: #787878;
$palette-gray-30: #4a4a4a;
$palette-white: #fff;

$color-primary:     color-map('primary', $palette-blue-30);
$color-background:  color-map('background', $palette-gray-95);
$color-content:     color-map('content', $palette-white);
$color-border:      color-map('border', $palette-gray-90);
$color-border-dk:   color-map('border-dk', $palette-gray-60);
$color-link:        color-map('link', $palette-blue-50);
$color-subtle:      color-map('subtle', $palette-gray-50);
$color-transparent: color-map('transparent', transparent);
$color-danger:      color-map('danger', $palette-red);
$color-alert:       color-map('alert', $palette-yellow-90);

$site-width: 960px;
$site-width-narrow: 480px;

$spacing-small: .5rem;
$spacing-medium: 1rem;
$spacing-large: 2rem;

$font-size-normal: 1rem;
$font-size-small: .8rem;

$line-height: 1.4;

$radius1: 4px;
