.c-selectBox {
  $inner-border-width: 1px;
  $inner-padding: 1em;

  font-size: .9em;
  margin: $spacing-small 0;
  position: relative;

  @media (max-width: 425px) {
    font-size: .8em;
  }

  &__input {
    font-size: 1em;
    margin: $inner-border-width;
    position: absolute;
      top: $inner-padding;
      left: $inner-padding;
    width: 1em;
    height: 1em * $line-height;
  }

  &__inner {
    border: $inner-border-width solid $color-border;
    border-radius: $radius1;
  }

  &__head {
    display: flex;
  }

  &__label {
    cursor: pointer;
    flex: 1;
    padding: $inner-padding;
    padding-left: 1em + ($inner-padding * 2);
  }

  &__addon {
    padding: $inner-padding;
  }

  &__help, &__expandableContainer {
    border-top: 1px solid $color-border;
    padding: $inner-padding;
  }

  &__disabled {
    opacity: 0.5;
  }
}

// Flexbox fallback (requires Modernizr)
.no-flexbox .c-selectBox {
    &__inner {
      overflow: hidden;
    }

    &__label {
      display: block;
      float: left;
    }

    &__addon {
      display: block;
      float: right;
    }

    &__help {
      display: block;
      clear: both;
    }
}


// States
.c-selectBox {

  &__input:checked + &__inner &__head {
    background: $color-background;
  }
}


// Mods
.c-selectBox--borderless {
    .c-selectBox__inner {
        border: none;
    }

    // this is very messy
    .c-selectBox__input:checked {
        + .c-selectBox__inner {
            .c-selectBox__head {
                background: none;
            }
        }
    }
}
