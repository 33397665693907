.c-button {
    display: inline-block;
    border: none;
    border-radius: $radius1;
    cursor: pointer;
    font: inherit;
    font-weight: bolder;
    line-height: 1;
    padding: $spacing-medium $spacing-large;
    text-align: center;
    text-decoration: none;

    @media screen and (max-width: 468px) {
        width: 100%;
    }
}

.c-button[disabled],
.c-button--disabled {
  background: $color-border;
  color: #999;
}

.c-button--fullwidth {
    width: 100%;
}

.c-button--mediumwidth {
    width: 75%;
}

.c-button--primary {
    background: $color-primary;
    color: white;
}

.c-button--link {
    background: transparent;
    color: $color-link;
    padding: 0;
}

.c-button--slim {
    padding: $spacing-small $spacing-medium;
}

.c-button__image {
    display: inline-block;
    vertical-align: bottom;
}
